import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image'; 

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';

const query = graphql`
    query uberUnsData {
        pageContent: allFile(filter: {relativePath: {eq: "uber-uns.md"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  background {
                    image {
                      childImageSharp {
                        fluid(maxWidth: 1440, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    text
                  }
                  content {
                    heading
                    text
                    images {
                      image {
                        childImageSharp {
                          fixed(width: 430, height: 560, quality: 100, webpQuality: 100) {
                            ...GatsbyImageSharpFixed
                            originalName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
    }
`;

const UberUns = () => {
    const getData = useStaticQuery(query);
    const data = getData.pageContent.nodes[0].childMarkdownRemark.frontmatter;

    return (
        <Layout>
            <SEO title='Über Uns' description='Als ausgebildete Ernährungsberaterin liegt es mir am Herzen mit meinem Konzept Fit o Fago bunte und leckere Gerichte zu kreieren, bei denen weder auf Geschmack noch auf wichtige Nährstoffe verzichtet werden muss.' />
            <Header image={data.background.image.childImageSharp.fluid} heading={data.background.text} />
            <div className="page__content">
                <div className="container">
                    <h3 className="page__title">
                        {data.content.heading}
                    </h3>
                    <div dangerouslySetInnerHTML={{ __html: data.content.text }} />
                    <div className="images">
                        {data.content.images.map((img, index) => (
                            <div className="image-container" key={index}>
                                <Img fixed={img.image.childImageSharp.fixed} className="image" alt={img.image.childImageSharp.fixed.originalName} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default UberUns;